const autoNgTemplateLoaderTemplate1 = require('./views/sidebar/sidebar.html');
const autoNgTemplateLoaderTemplate2 = require('./views/account/account.html');
const autoNgTemplateLoaderTemplate3 = require('./views/auth/auth.html');
const autoNgTemplateLoaderTemplate4 = require('./views/logout/logout.html');
const autoNgTemplateLoaderTemplate5 = require('./views/endpoints/endpoints.html');
const autoNgTemplateLoaderTemplate6 = require('./views/endpoints/edit/endpoint.html');
const autoNgTemplateLoaderTemplate7 = require('../kubernetes/views/configure/configure.html');
const autoNgTemplateLoaderTemplate8 = require('./views/endpoints/create/createendpoint.html');
const autoNgTemplateLoaderTemplate9 = require('./views/endpoints/access/endpointAccess.html');
const autoNgTemplateLoaderTemplate10 = require('./views/groups/groups.html');
const autoNgTemplateLoaderTemplate11 = require('./views/groups/edit/group.html');
const autoNgTemplateLoaderTemplate12 = require('./views/groups/create/creategroup.html');
const autoNgTemplateLoaderTemplate13 = require('./views/groups/access/groupAccess.html');
const autoNgTemplateLoaderTemplate14 = require('./views/home/home.html');
const autoNgTemplateLoaderTemplate15 = require('./views/init/endpoint/initEndpoint.html');
const autoNgTemplateLoaderTemplate16 = require('./views/init/admin/initAdmin.html');
const autoNgTemplateLoaderTemplate17 = require('./views/registries/registries.html');
const autoNgTemplateLoaderTemplate18 = require('./views/registries/edit/registry.html');
const autoNgTemplateLoaderTemplate19 = require('./views/settings/settings.html');
const autoNgTemplateLoaderTemplate20 = require('./views/settings/authentication/settingsAuthentication.html');
const autoNgTemplateLoaderTemplate21 = require('./views/tags/tags.html');
const autoNgTemplateLoaderTemplate22 = require('./views/users/users.html');
const autoNgTemplateLoaderTemplate23 = require('./views/users/edit/user.html');
const autoNgTemplateLoaderTemplate24 = require('./views/teams/teams.html');
const autoNgTemplateLoaderTemplate25 = require('./views/teams/edit/team.html');
const autoNgTemplateLoaderTemplate26 = require('./views/roles/roles.html');

import _ from 'lodash-es';

import componentsModule from './components';
import settingsModule from './settings';

async function initAuthentication(authManager, Authentication, $rootScope, $state) {
  authManager.checkAuthOnRefresh();
  // The unauthenticated event is broadcasted by the jwtInterceptor when
  // hitting a 401. We're using this instead of the usual combination of
  // authManager.redirectWhenUnauthenticated() + unauthenticatedRedirector
  // to have more controls on which URL should trigger the unauthenticated state.
  $rootScope.$on('unauthenticated', function (event, data) {
    if (!_.includes(data.config.url, '/v2/') && !_.includes(data.config.url, '/api/v4/') && isTransitionRequiresAuthentication($state.transition)) {
      $state.go('portainer.logout', { error: 'Your session has expired' });
    }
  });

  return await Authentication.init();
}

angular.module('portainer.app', ['portainer.oauth', componentsModule, settingsModule]).config([
  '$stateRegistryProvider',
  function ($stateRegistryProvider) {
    'use strict';

    var root = {
      name: 'root',
      abstract: true,
      onEnter: /* @ngInject */ function onEnter($async, StateManager, Authentication, Notifications, authManager, $rootScope, $state) {
        return $async(async () => {
          const appState = StateManager.getState();
          if (!appState.loading) {
            return;
          }
          try {
            const loggedIn = await initAuthentication(authManager, Authentication, $rootScope, $state);
            await StateManager.initialize();
            if (!loggedIn && isTransitionRequiresAuthentication($state.transition)) {
              $state.go('portainer.logout');
              return Promise.reject('Unauthenticated');
            }
          } catch (err) {
            Notifications.error('Failure', err, 'Unable to retrieve application settings');
            throw err;
          }
        });
      },
      views: {
        'sidebar@': {
          templateUrl: autoNgTemplateLoaderTemplate1,
          controller: 'SidebarController',
        },
      },
    };

    var endpointRoot = {
      name: 'endpoint',
      url: '/:endpointId',
      parent: 'root',
      abstract: true,
      resolve: {
        endpoint: /* @ngInject */ function endpoint($async, $state, $transition$, EndpointService, Notifications) {
          return $async(async () => {
            try {
              const endpointId = +$transition$.params().endpointId;

              const endpoint = await EndpointService.endpoint(endpointId);
              if ((endpoint.Type === 4 || endpoint.Type === 7) && !endpoint.EdgeID) {
                $state.go('portainer.endpoints.endpoint', { id: endpoint.Id });
                return;
              }

              return endpoint;
            } catch (e) {
              Notifications.error('Failed loading environment', e);
              $state.go('portainer.home', {}, { reload: true });
              return;
            }
          });
        },
      },
    };

    var portainer = {
      name: 'portainer',
      parent: 'root',
      abstract: true,
    };

    var account = {
      name: 'portainer.account',
      url: '/account',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate2,
          controller: 'AccountController',
        },
      },
    };

    var authentication = {
      name: 'portainer.auth',
      url: '/auth',
      params: {
        reload: false,
      },
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate3,
          controller: 'AuthenticationController',
          controllerAs: 'ctrl',
        },
        'sidebar@': {},
      },
    };
    const logout = {
      name: 'portainer.logout',
      url: '/logout',
      params: {
        error: '',
        performApiLogout: false,
      },
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate4,
          controller: 'LogoutController',
          controllerAs: 'ctrl',
        },
        'sidebar@': {},
      },
    };

    var endpoints = {
      name: 'portainer.endpoints',
      url: '/endpoints',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate5,
          controller: 'EndpointsController',
        },
      },
    };

    var endpoint = {
      name: 'portainer.endpoints.endpoint',
      url: '/:id',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate6,
          controller: 'EndpointController',
        },
      },
    };

    const endpointKubernetesConfiguration = {
      name: 'portainer.endpoints.endpoint.kubernetesConfig',
      url: '/configure',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate7,
          controller: 'KubernetesConfigureController',
          controllerAs: 'ctrl',
        },
      },
    };

    var endpointCreation = {
      name: 'portainer.endpoints.new',
      url: '/new',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate8,
          controller: 'CreateEndpointController',
        },
      },
    };

    var endpointAccess = {
      name: 'portainer.endpoints.endpoint.access',
      url: '/access',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate9,
          controller: 'EndpointAccessController',
          controllerAs: 'ctrl',
        },
      },
    };

    var groups = {
      name: 'portainer.groups',
      url: '/groups',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate10,
          controller: 'GroupsController',
        },
      },
    };

    var group = {
      name: 'portainer.groups.group',
      url: '/:id',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate11,
          controller: 'GroupController',
        },
      },
    };

    var groupCreation = {
      name: 'portainer.groups.new',
      url: '/new',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate12,
          controller: 'CreateGroupController',
        },
      },
    };

    var groupAccess = {
      name: 'portainer.groups.group.access',
      url: '/access',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate13,
          controller: 'GroupAccessController',
        },
      },
    };

    var home = {
      name: 'portainer.home',
      url: '/home',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate14,
          controller: 'HomeController',
        },
      },
    };

    var init = {
      name: 'portainer.init',
      abstract: true,
      url: '/init',
      views: {
        'sidebar@': {},
      },
    };

    const wizard = {
      name: 'portainer.wizard',
      url: '/wizard',
      views: {
        'content@': {
          component: 'wizardView',
        },
      },
    };

    const wizardEndpoints = {
      name: 'portainer.wizard.endpoints',
      url: '/endpoints',
      views: {
        'content@': {
          component: 'wizardEndpoints',
        },
      },
    };

    var initEndpoint = {
      name: 'portainer.init.endpoint',
      url: '/endpoint',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate15,
          controller: 'InitEndpointController',
          controllerAs: 'ctrl',
        },
      },
    };

    var initAdmin = {
      name: 'portainer.init.admin',
      url: '/admin',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate16,
          controller: 'InitAdminController',
        },
      },
    };

    var registries = {
      name: 'portainer.registries',
      url: '/registries',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate17,
          controller: 'RegistriesController',
        },
      },
    };

    var registry = {
      name: 'portainer.registries.registry',
      url: '/:id',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate18,
          controller: 'RegistryController',
        },
      },
    };

    const registryCreation = {
      name: 'portainer.registries.new',
      url: '/new',
      views: {
        'content@': {
          component: 'createRegistry',
        },
      },
    };

    var settings = {
      name: 'portainer.settings',
      url: '/settings',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate19,
          controller: 'SettingsController',
        },
      },
    };

    var settingsAuthentication = {
      name: 'portainer.settings.authentication',
      url: '/auth',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate20,
          controller: 'SettingsAuthenticationController',
        },
      },
    };

    var tags = {
      name: 'portainer.tags',
      url: '/tags',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate21,
          controller: 'TagsController',
        },
      },
    };

    var users = {
      name: 'portainer.users',
      url: '/users',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate22,
          controller: 'UsersController',
        },
      },
    };

    var user = {
      name: 'portainer.users.user',
      url: '/:id',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate23,
          controller: 'UserController',
        },
      },
    };

    var teams = {
      name: 'portainer.teams',
      url: '/teams',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate24,
          controller: 'TeamsController',
        },
      },
    };

    var team = {
      name: 'portainer.teams.team',
      url: '/:id',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate25,
          controller: 'TeamController',
        },
      },
    };

    var roles = {
      name: 'portainer.roles',
      url: '/roles',
      views: {
        'content@': {
          templateUrl: autoNgTemplateLoaderTemplate26,
        },
      },
    };

    $stateRegistryProvider.register(root);
    $stateRegistryProvider.register(endpointRoot);
    $stateRegistryProvider.register(portainer);
    $stateRegistryProvider.register(account);
    $stateRegistryProvider.register(authentication);
    $stateRegistryProvider.register(logout);
    $stateRegistryProvider.register(endpoints);
    $stateRegistryProvider.register(endpoint);
    $stateRegistryProvider.register(endpointAccess);
    $stateRegistryProvider.register(endpointCreation);
    $stateRegistryProvider.register(endpointKubernetesConfiguration);
    $stateRegistryProvider.register(groups);
    $stateRegistryProvider.register(group);
    $stateRegistryProvider.register(groupAccess);
    $stateRegistryProvider.register(groupCreation);
    $stateRegistryProvider.register(home);
    $stateRegistryProvider.register(init);
    $stateRegistryProvider.register(wizard);
    $stateRegistryProvider.register(wizardEndpoints);
    $stateRegistryProvider.register(initEndpoint);
    $stateRegistryProvider.register(initAdmin);
    $stateRegistryProvider.register(registries);
    $stateRegistryProvider.register(registry);
    $stateRegistryProvider.register(registryCreation);
    $stateRegistryProvider.register(settings);
    $stateRegistryProvider.register(settingsAuthentication);
    $stateRegistryProvider.register(tags);
    $stateRegistryProvider.register(users);
    $stateRegistryProvider.register(user);
    $stateRegistryProvider.register(teams);
    $stateRegistryProvider.register(team);
    $stateRegistryProvider.register(roles);
  },
]);

function isTransitionRequiresAuthentication(transition) {
  const UNAUTHENTICATED_ROUTES = ['portainer.logout', 'portainer.auth'];
  if (!transition) {
    return true;
  }
  const nextTransition = transition && transition.to();
  const nextTransitionName = nextTransition ? nextTransition.name : '';
  return !UNAUTHENTICATED_ROUTES.some((route) => nextTransitionName.startsWith(route));
}
