const autoNgTemplateLoaderTemplate1 = require('./git-form-info-panel.html');

export const gitFormInfoPanel = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    url: '<',
    configFilePath: '<',
    additionalFiles: '<',
    className: '@',
  },
};
