const autoNgTemplateLoaderTemplate1 = require('./box-selector-item.html');

import angular from 'angular';

angular.module('portainer.app').component('boxSelectorItem', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    radioName: '@',
    isChecked: '<',
    option: '<',
    onChange: '<',
    disabled: '<',
    tooltip: '<',
  },
});
