const autoNgTemplateLoaderTemplate1 = require('./git-form-compose-path-field.html');

export const gitFormComposePathField = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    deployMethod: '@',

    value: '<',
    onChange: '<',
  },
};
